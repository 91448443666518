import React, { Fragment, useContext, useEffect } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { Filter, SearchBox, Sorting, WithSearch } from "@elastic/react-search-ui";

import "@elastic/react-search-ui-views/lib/styles/styles.css";

import { SortDirectionEnum } from "../../models/dataModel";
import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";

import { SearchFieldInput } from "../SearchField";
import { Facets } from "./Facets";

const SortBy = injectIntl(({ intl }: { intl: IntlShape }): JSX.Element => {
  return (
    <Fragment>
      <h4 data-testid="sortByLabel">
        <FormattedMessage description="sortingLabel" id="sortBy" />
      </h4>
      <Sorting
        sortOptions={[
          {
            name: intl.formatMessage({ id: "sortBy.mostRelevant" }),
            value: "",
            direction: "",
          },
          {
            name: intl.formatMessage({ id: "sortBy.title" }),
            value: "title",
            direction: SortDirectionEnum.ASCENDING,
          },
        ]}
      />
    </Fragment>
  );
});

export const SidebarSearchField = ({ filters }: { filters?: Filter[] }): JSX.Element => {
  const uiStore: UIStateStore = useContext(UIStateStoreContext);

  useEffect(() => {
    !!filters && uiStore.setAutocompleteFilters(filters);

  }, [filters]);

  return (
    <div className="search-field">
      <SearchBox
        autocompleteMinimumCharacters={3}
        autocompleteResults={{
          linkTarget: "_blank",
          titleField: "title",
          urlField: "url",
          shouldTrackClickThrough: true,
          clickThroughTags: ["autocomplete"],
        }}
        autocompleteSuggestions={true}
        searchAsYouType={false}
        shouldClearFilters={false}
        inputView={SearchFieldInput}
      />
    </div>
  );
};

/**
 * Renders the search field & facets.
 */
export const SidebarContent = ({ toggleSidebar }: { toggleSidebar }): JSX.Element => {
  return (
    <div className="sui-layout-sidebar__inner" data-testid="sidebarContent">
      <WithSearch mapContextToProps={({ wasSearched, filters }) => ({ wasSearched, filters })}>
        {({ wasSearched, filters }: { wasSearched: boolean; filters: Filter[] }) => (
          <Fragment>
            <SidebarSearchField filters={filters} />
            {wasSearched && (
              <div className="facet-list" data-testid="facetList">
                <SortBy />
                <Facets toggleSidebar={toggleSidebar} />
              </div>
            )}
          </Fragment>
        )}
      </WithSearch>
    </div>
  );
};
