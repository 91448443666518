import { FacetProps } from "@elastic/react-search-ui";
import { MultiSelectFacet } from "./components/facets/MultiSelectFacet";
import { SelectCategoryFacet } from "./components/facets/SelectCategoryFacet";
import { SelectProductFacet } from "./components/facets/SelectProductFacet";
import { SelectVersionFacet } from "./components/facets/SelectVersionFacet";
import { FilterOptionEnum, FilterTypeEnum } from "./models/dataModel";

interface ISearchConfig {
  endpoint: string;
  services: Record<string, ISearchService>;
  global: {
    facetList: FacetProps[];
    filters: string[];
    resultFields: string[];
  };
}

interface ISearchService {
  categoryName: string;
  facetList?: FacetProps[];
  filters: string[];
  resultFields: string[];
}

const config: ISearchConfig = {
  endpoint:
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://api.search.cloud.tekla.com/query/v1.0/"
      : "https://api.search.stg.cloud.tekla.com/query/v1.0/",
  services: {
    tua: {
      categoryName: "User Assistance",
      facetList: [
        {
          field: FilterOptionEnum.PRODUCT,
          filterType: FilterTypeEnum.ALL,
          label: `facets.tua.${FilterOptionEnum.PRODUCT}`,
          view: SelectProductFacet,
        },
        {
          field: FilterOptionEnum.VERSION,
          filterType: FilterTypeEnum.ANY,
          label: `facets.tua.${FilterOptionEnum.VERSION}`,
          view: SelectVersionFacet,
        },
        {
          field: FilterOptionEnum.CONTENT_AREA,
          filterType: FilterTypeEnum.ALL,
          label: `facets.tua.${FilterOptionEnum.CONTENT_AREA}`,
          view: MultiSelectFacet,
        },
        {
          field: FilterOptionEnum.TYPE,
          filterType: FilterTypeEnum.ANY,
          label: `facets.tua.${FilterOptionEnum.TYPE}`,
          view: MultiSelectFacet,
        },
        {
          field: FilterOptionEnum.ENVIRONMENT,
          filterType: FilterTypeEnum.ALL,
          label: `facets.tua.${FilterOptionEnum.ENVIRONMENT}`,
          view: MultiSelectFacet,
        },
      ],
      filters: ["product", "software_version", "content_area", "type", "environment", "tags"],
      resultFields: [
        "id",
        "url",
        "title",
        "teaser",
        "tags",
        "type",
        "environment",
        "software_version",
        "product",
        "preview_image_url",
      ],
    },
    com: {
      categoryName: "Product Information",
      filters: [],
      resultFields: ["id", "url", "title", "teaser", "content_type"],
    },
    down: {
      categoryName: "Download",
      filters: [],
      resultFields: ["id", "url", "title", "teaser", "content_type"],
    },
    devctr: {
      categoryName: "Developer APIs",
      facetList: [
        {
          field: FilterOptionEnum.PRODUCT,
          filterType: FilterTypeEnum.ALL,
          label: `facets.tua.${FilterOptionEnum.PRODUCT}`,
          view: SelectProductFacet,
        },
        {
          field: FilterOptionEnum.VERSION,
          filterType: FilterTypeEnum.ANY,
          label: `facets.tua.${FilterOptionEnum.VERSION}`,
          view: SelectVersionFacet,
        },
        {
          field: FilterOptionEnum.TYPE,
          filterType: FilterTypeEnum.ANY,
          label: `facets.tua.${FilterOptionEnum.TYPE}`,
          view: MultiSelectFacet,
        },
      ],
      filters: ["product", "software_version", "type"],
      resultFields: ["id", "url", "title", "teaser", "type", "product", "software_version"],
    },
  },
  global: {
    facetList: [
      {
        field: FilterOptionEnum.CATEGORY,
        filterType: FilterTypeEnum.ALL,
        label: `facets.category`,
        view: SelectCategoryFacet,
      },
    ],
    filters: ["content_type"],
    resultFields: ["id", "url", "title", "teaser", "content_type"],
  },
};

export default config;
