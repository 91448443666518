import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { FacetValue, Filter, func, WithSearch } from "@elastic/react-search-ui";

import { FilterOptionEnum } from "../../models/dataModel";
import { hasProductBeenSelected } from "../../utils/functions";
import { RadioButtonFacet } from "./RadioButtonFacet";

const FacetWrapper = injectIntl(
  ({
    intl,
    labelKey,
    options,
    showMore,
    onChange,
    onMoreClick,
  }: {
    intl: IntlShape;
    labelKey: string;
    options: FacetValue[];
    showMore: boolean;
    onChange: func;
    onMoreClick: func;
  }) => {
    const allProductsStr = intl.formatMessage({ id: "facets.tua.product.allProducts" });

    function getAllProductsCount(): number {
      return options.reduce((counter: number, option: FacetValue) => {
        if (option.count) counter += option.count;
        return counter;
      }, 0);
    }

    function getProductOptions(filters: Filter[]): FacetValue[] {
      if (options[0].value !== allProductsStr)
        options.unshift({
          count: getAllProductsCount(),
          value: allProductsStr,
          selected: !hasProductBeenSelected(filters),
        });

      return options;
    }

    function selectProductOption(option: string, removeFilter: func) {
      option === allProductsStr ? removeFilter(FilterOptionEnum.PRODUCT) : onChange(option);
    }

    return (
      <WithSearch mapContextToProps={({ filters, removeFilter }) => ({ filters, removeFilter })}>
        {({ filters, removeFilter }: { filters: Filter[]; removeFilter: func }) => (
          <RadioButtonFacet
            label={labelKey}
            options={getProductOptions(filters)}
            showMore={showMore}
            onChange={(option) => selectProductOption(option, removeFilter)}
            onMoreClick={onMoreClick}
          />
        )}
      </WithSearch>
    );
  },
);

/**
 * Renders the "Select product" facet.
 * If no product has been selected, sets the filter as "All products".
 * @param label name of the facet
 * @param options options for the facet
 * @param onChange handles selecting an option
 */
export const SelectProductFacet = ({
  label,
  options,
  showMore,
  onChange,
  onMoreClick,
}: {
  label: string;
  options: FacetValue[];
  showMore: boolean;
  onChange: func;
  onMoreClick: func;
}): JSX.Element => {
  return (
    <FacetWrapper
      labelKey={label}
      options={options}
      showMore={showMore}
      onChange={onChange}
      onMoreClick={onMoreClick}
    />
  );
};
