import React from "react";
import Pagination from "rc-pagination";
import { WithSearch } from "@elastic/react-search-ui";
import { injectIntl, IntlShape } from "react-intl";

import cs_CZ from "rc-pagination/lib/locale/cs_CZ.js";
import de_DE from "rc-pagination/lib/locale/de_DE.js";
import en_US from "rc-pagination/lib/locale/en_US.js";
import es_ES from "rc-pagination/lib/locale/es_ES.js";
import et_EE from "rc-pagination/lib/locale/et_EE.js";
import fi_FI from "rc-pagination/lib/locale/fi_FI.js";
import fr_FR from "rc-pagination/lib/locale/fr_FR.js";
import id_ID from "rc-pagination/lib/locale/id_ID.js";
import it_IT from "rc-pagination/lib/locale/it_IT.js";
import ja_JP from "rc-pagination/lib/locale/ja_JP.js";
import ko_KR from "rc-pagination/lib/locale/ko_KR.js";
import nl_NL from "rc-pagination/lib/locale/nl_NL.js";
import pl_PL from "rc-pagination/lib/locale/pl_PL.js";
import pt_PT from "rc-pagination/lib/locale/pt_PT.js";
import pt_BR from "rc-pagination/lib/locale/pt_BR.js";
import ru_RU from "rc-pagination/lib/locale/ru_RU.js";
import sv_SE from "rc-pagination/lib/locale/sv_SE.js";
import th_TH from "rc-pagination/lib/locale/th_TH.js";
import zh_HANS from "rc-pagination/lib/locale/zh_CN.js";

import { ReactComponent as PrevIcon } from "../../assets/icons/open_carret_left.svg";
import { ReactComponent as NextIcon } from "../../assets/icons/open_carret_right.svg";

/**
 * Renders the custom paging component with our icons.
 */
export const CustomPaging = injectIntl(({ intl }: { intl: IntlShape }): JSX.Element => {
  const intlLocale: string = intl.locale;

  const locales = {
    cs: cs_CZ,
    de: de_DE,
    en: en_US,
    es: es_ES,
    ee: et_EE,
    et: et_EE,
    fi: fi_FI,
    fr: fr_FR,
    id: id_ID,
    it: it_IT,
    ja: ja_JP,
    jp: ja_JP,
    ko: ko_KR,
    kr: ko_KR,
    nl: nl_NL,
    pl: pl_PL,
    "pt-pt": pt_PT,
    "pt-br": pt_BR,
    ru: ru_RU,
    sv: sv_SE,
    th: th_TH,
    "zh-hans": zh_HANS,
  };

  return (
    <WithSearch
      mapContextToProps={({ resultsPerPage, totalResults, current, setCurrent }) => ({
        resultsPerPage,
        totalResults,
        current,
        setCurrent,
      })}
    >
      {({ resultsPerPage, totalResults, current, setCurrent }) => (
        <div data-testid="customPagination">
          <Pagination
            current={current}
            onChange={setCurrent}
            pageSize={resultsPerPage}
            locale={locales[intlLocale]}
            total={totalResults}
            className="sui-paging"
            prevIcon={<PrevIcon title={intl.formatMessage({ id: "icon.previous.title" })} />}
            nextIcon={<NextIcon title={intl.formatMessage({ id: "icon.next.title" })} />}
          />
        </div>
      )}
    </WithSearch>
  );
});
