import React, { useContext } from "react";
import { WithSearch } from "@elastic/react-search-ui";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { ReactComponent as RemoveIcon } from "../../assets/icons/enclosed_x.svg";
import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";

/**
 * Renders the 'Clear filters' button.
 */
export const ClearFilters = injectIntl(({ intl }: { intl: IntlShape }): JSX.Element => {
  const uiStore: UIStateStore = useContext(UIStateStoreContext);
  const removeIconTitle: string = intl.formatMessage({ id: "actions.clearFilters" });

  return (
    <WithSearch mapContextToProps={({ clearFilters }) => ({ clearFilters })}>
      {({ clearFilters }) => {
        function clearAll() {
          !!uiStore.inGlobalSearchMode() && uiStore.setOpenCategory(undefined);
          uiStore.setOpenCategoryFilters([], true);
          clearFilters();
        }

        return (
          <button className="clear-filters" onClick={clearAll} data-testid="clearFiltersButton">
            <RemoveIcon title={removeIconTitle} />
            <FormattedMessage id="actions.clearFilters" />
          </button>
        );
      }}
    </WithSearch>
  );
});
