import { Context, Filter } from "@elastic/react-search-ui";
import _ from "lodash";

import { IRequestBody, IResponse } from "../models/dataModel";
import runRequest from "./runRequest";
import buildRequest from "./buildRequest";
import { AuthStore } from "../stores/authStore";
import { UIStateStore } from "../stores/uiStateStore";

function combineAggregationsFromResponses(responses: IResponse[]) {
  return responses.reduce((acc, response) => {
    return {
      ...acc,
      ...response.aggregations,
    };
  }, {});
}

// To calculate a disjunctive facet correctly, you need to calculate the facet counts as if the filter was
// not applied. If you did not do this, list of facet values would collapse to just one value, which is
// whatever you have filtered on in that facet.
function removeFilterByName(state: Context, facetName: string) {
  return {
    ...state,
    filters: (state.filters || []).filter((f: Filter) => f.field !== facetName),
  };
}

function removeAllFacetsExcept(body: IRequestBody, facetName: string) {
  return {
    ...body,
    aggs: {
      [facetName]: body.aggs[facetName],
    },
  };
}

function changeSizeToZero(body: IRequestBody) {
  return {
    ...body,
    size: 0,
  };
}

async function getDisjunctiveFacetCounts(
  service: string,
  index: string,
  state: Context,
  disjunctiveFacetNames: string[],
  authorizer: AuthStore,
  uiStore: UIStateStore,
) {
  // only includes facets to which filters are currently applied
  disjunctiveFacetNames = _.filter(disjunctiveFacetNames, (facetName: string) =>
    _.some(state.filters || [], (filter: Filter) => filter.field === facetName),
  );

  const responses = await Promise.all(
    disjunctiveFacetNames.map(async (facetName: string) => {
      const newState = removeFilterByName(state, facetName);
      let body = await buildRequest(index, service, newState, authorizer, uiStore);
      body = changeSizeToZero(body);
      body = removeAllFacetsExcept(body, facetName);
      return runRequest(index, body, authorizer);
    }),
  );
  return combineAggregationsFromResponses(responses);
}

/**
 * This function will re-calculate facets that need to be considered
 * "disjunctive" (also known as "sticky").
 * Disjunctive facets are facets that do not change when a selection is made.
 * Meaning, all available options will remain as selectable options even after
 * a selection is made. Calculating sticky facets correctly requires a second
 * query for each sticky facet.
 *
 * @param {string} service
 * @param {string} index
 * @param {JSON} json
 * @param {Context} state
 * @param {string[]} disunctiveFacetNames
 *
 * @return {Promise<IResponse>} A map of updated aggregation counts for the specified facet names
 */
export default async function applyDisjunctiveFaceting(
  service: string,
  index: string,
  json: IResponse,
  state: Context,
  disunctiveFacetNames: string[],
  authorizer: AuthStore,
  uiStore: UIStateStore,
): Promise<IResponse> {
  const disjunctiveFacetCounts = await getDisjunctiveFacetCounts(
    service,
    index,
    state,
    disunctiveFacetNames,
    authorizer,
    uiStore,
  );

  return {
    ...json,
    aggregations: {
      ...json.aggregations,
      ...disjunctiveFacetCounts,
    },
  };
}
