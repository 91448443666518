import React, { Fragment, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react-lite";
import _ from "lodash";

import { FacetValue, Filter, func, WithSearch } from "@elastic/react-search-ui";

import { FilterOptionEnum } from "../../models/dataModel";
import { hasProductBeenSelected } from "../../utils/functions";

import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";
import { FacetTitle } from "./FacetTitle";
import { MultiSelectFacet } from "./MultiSelectFacet";

const FacetWrapper = observer(
  ({
    labelKey,
    options,
    showMore,
    onRemove,
    onSelect,
    onMoreClick,
  }: {
    labelKey: string;
    options: FacetValue[];
    showMore: boolean;
    onRemove: func;
    onSelect: func;
    onMoreClick: func;
  }): JSX.Element => {
    const uiStore: UIStateStore = useContext(UIStateStoreContext);
    const isOpen = uiStore.isFacetOpen(FilterOptionEnum.VERSION);

    function sortedVersions(): FacetValue[] {
      const sorted = _.sortBy(options, (version: FacetValue) => {
        const versionStr = version.value.toString();
        const versionAsNumber: number = versionStr.endsWith("i")
          ? Number(versionStr.substring(0, versionStr.length - 1)) + 0.5
          : Number(version.value);

        if (!_.isNaN(versionAsNumber)) {
          return versionAsNumber;
        } else if (version.value === "Not version-specific") {
          return 9999;
        } else {
          return 0;
        }
      });

      return sorted.reverse();
    }

    return (
      <WithSearch mapContextToProps={({ filters }) => ({ filters })}>
        {({ filters }: { filters: Filter[] }) => (
          <Fragment>
            {hasProductBeenSelected(filters) ? (
              <MultiSelectFacet
                label={labelKey}
                options={sortedVersions()}
                onRemove={onRemove}
                onSelect={onSelect}
                showMore={showMore}
                onMoreClick={onMoreClick}
              />
            ) : (
              <div className="disabled" data-testid="cantSelectVersion">
                <FacetTitle titleKey={labelKey} />
                {isOpen && (
                  <p className="small-text" data-testid="mustSelectProduct">
                    <FormattedMessage id="mustSelectProduct" />
                  </p>
                )}
              </div>
            )}
          </Fragment>
        )}
      </WithSearch>
    );
  },
);

/**
 * Renders the "Select version" facet.
 * If no product has been selected, displays a 'Must select product first' message.
 * @param label name of the facet
 * @param options options for the facet
 * @param onSelect handles selecting an option
 * @param onRemove handles removing an option
 */
export const SelectVersionFacet = ({
  label,
  options,
  showMore,
  onRemove,
  onSelect,
  onMoreClick,
}: {
  label: string;
  options: FacetValue[];
  showMore: boolean;
  onRemove: func;
  onSelect: func;
  onMoreClick: func;
}): JSX.Element => {
  return (
    <FacetWrapper
      labelKey={label}
      options={options}
      onRemove={onRemove}
      onSelect={onSelect}
      showMore={showMore}
      onMoreClick={onMoreClick}
    />
  );
};
