import { FacetT, FieldValue, FieldValueWrapper, FilterValue, Result, SortDirection } from "@elastic/react-search-ui";

export interface IAggregation {
  buckets: IBucket[];
  doc_count_error_upper_bound?: number;
  sum_other_doc_count?: number;
}

export interface IBucket {
  key_as_string?: string;
  key: string | number | boolean | (string | number | boolean)[];
  doc_count: number;
}

export interface IResponse {
  linkUrl: string;
  aggregations: Record<string, IAggregation>;
  hits?: ISearchResponse;
  timed_out: boolean;
  took: number;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
  };
}

export interface IRequestBody {
  sort?: Record<string, SortDirection>[];
  size?: number;
  from?: number;
  highlight: {
    fragment_size: number;
    number_of_fragments: number;
    fields: Record<string, unknown>;
  };
  _source: string[];
  aggs: Record<string, ITermsAggregation>;
  query: {
    bool: IBooleanQuery;
  };
}

interface ITerm {
  term: Record<string, string | undefined>;
}

interface IBooleanQueryOptions {
  [x: string]:
    | string
    | number
    | IShouldQuery[]
    | ITermFilter[]
    | Record<string, string | number | undefined | IBooleanQueryOptions>
    | Record<string, string | number | undefined | IBooleanQueryOptions>[];
}

export interface ITermFilter {
  bool?: IBooleanQuery;
  term?: Record<string, FilterValue | FilterValue[] | IBooleanQueryOptions>;
}

export interface IShouldQuery {
  bool?: IBooleanQueryOptions;
  term?: Record<string, FilterValue | FilterValue[] | IBooleanQueryOptions>;
  match_phrase?: Record<string, IQuery>;
  match?: Record<string, IQuery>;
  match_all?: Record<string, IQuery>;
}

interface IQueryClauses {
  // leaf query clauses
  term?: Record<string, FilterValue | FilterValue[] | IBooleanQueryOptions>;
  exists?: Record<string, string>;
  multi_match?: IQuery;
  match_all?: Record<string, unknown>;
  match_phrase?: Record<string, IQuery>;
  match?: Record<string, IQuery>;
  // compound query clauses
  bool?: IBooleanQueryOptions;
}

// https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-bool-query.html
interface IBooleanQuery {
  filter?: ITerm | ITermFilter[];
  must_not?: IQueryClauses[] | IQueryClauses | ITerm | ITermFilter[];
  must?: IQueryClauses[] | IQueryClauses;
  should?: IShouldQuery[];
  minimum_should_match?: number;
}

export interface IQuery {
  query?: string;
  operator?: string;
  boost?: number;
}

// https://www.elastic.co/guide/en/elasticsearch/reference/7.11/search-aggregations.html
export interface ITermsAggregation {
  terms: { field: string };
}

export interface ISearchResponse {
  hits: ISearchResultHit[];
  total: { value: number };
}

export interface IResponseState {
  results: Result[];
  totalResults: number;
  totalPages: number;
  facets?: Record<string, FacetT[]>;
}

export interface ISearchResultHit {
  highlight: Record<string, string>;
  _source: Record<string, FieldValue>;
}

export interface IResult {
  content_type?: FieldValueWrapper;
  id: FieldValueWrapper;
  owner?: FieldValueWrapper;
  preview_image_url?: FieldValueWrapper;
  product: FieldValueWrapper;
  software_version: FieldValueWrapper;
  sub_title?: FieldValueWrapper;
  tags: FieldValueWrapper;
  teaser: FieldValueWrapper;
  title: FieldValueWrapper;
  type: FieldValueWrapper;
  url: FieldValueWrapper;
}

export interface IBoolFilter {
  term?: Record<string, FilterValue>;
  terms?: Record<string, FilterValue[]>;
}

export interface ITag {
  label?: string;
  filters: IFilter[];
}

export interface IFilter {
  field: string;
  value: string;
  type: string;
}

export enum FilterTypeEnum {
  ALL = "all",
  ANY = "any",
  NONE = "none",
}

export enum SortDirectionEnum {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

export enum FilterOptionEnum {
  PRODUCT = "product",
  VERSION = "software_version",
  CONTENT_AREA = "content_area",
  CATEGORY = "content_type",
  TYPE = "type",
  ENVIRONMENT = "environment",
  TAGS = "tags",
}

export enum ResultTypeEnum {
  ARTICLE = "Article",
  BULLETIN = "Bulletin",
  COMPONENT_HELP = "Component help",
  COURSE = "Course",
  INTERNAL_ARTICLE = "Internal article",
  PRODUCT_GUIDE = "Product guide",
  RELEASE_NOTES = "Release notes",
  VIDEO = "Video",
}

export enum ServiceEnum {
  TEKLA_USER_ASSISTANCE = "tua",
  TEKLA_COM = "com",
  DOWNLOAD = "down",
  DEVELOPER_CENTER = "devctr",
}
