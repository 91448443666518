import React, { Fragment, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react-lite";
import { Facet, FacetProps } from "@elastic/react-search-ui";
import _ from "lodash";

import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter_2.svg";

import config from "../../config";
import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";

import { ClearFilters } from "../common/ClearFilters";

/**
 * Renders sub category filters.
 */
export const SubFacets = ({ openCategory }: { openCategory: string }): JSX.Element => {
  const facets: FacetProps[] | undefined = config.services[openCategory].facetList;

  return (
    <div className="sub-facets">
      {_.map(facets, (facet: FacetProps, i: number) => {
        return (
          <Facet
            field={facet.field}
            filterType={facet.filterType}
            label={facet.label}
            show={10}
            view={facet.view}
            key={i}
          />
        );
      })}
    </div>
  );
};

/**
 * Renders sidebar facets (=filters).
 */
export const Facets = observer(({ toggleSidebar }: { toggleSidebar }): JSX.Element => {
  const uiStore: UIStateStore = useContext(UIStateStoreContext);
  const service = uiStore.inGlobalSearchMode() ? config.global : config.services[uiStore.getService()];

  return (
    <Fragment>
      <hr />
      <h3 data-testid="filterResultsTitle">
        <FormattedMessage id="filterSearchResults" />
      </h3>
      {!!service.facetList &&
        _.map(service.facetList, (facet: FacetProps, i: number) => {
          return (
            <Facet
              field={facet.field}
              filterType={facet.filterType}
              label={facet.label}
              show={10}
              view={facet.view}
              key={i}
            />
          );
        })}
      <div className="centered">
        <button
          type="button"
          className="sui-layout-sidebar-toggle"
          onClick={() => toggleSidebar()}
          data-testid="saveFiltersButton"
        >
          <FilterIcon />
          <FormattedMessage id="actions.saveFilters" />
        </button>
        <ClearFilters />
      </div>
    </Fragment>
  );
});
