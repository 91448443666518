import { FacetT } from "@elastic/react-search-ui";
import { IAggregation } from "../models/dataModel";

function getValueFacet(aggregations: Record<string, IAggregation>, fieldName: string): FacetT[] | undefined {
  if (
    aggregations &&
    aggregations[fieldName] &&
    aggregations[fieldName].buckets &&
    aggregations[fieldName].buckets.length > 0
  ) {
    return [
      {
        field: fieldName,
        type: "value",
        data: aggregations[fieldName].buckets.map((bucket) => ({
          // Boolean values and date values require using `key_as_string`
          value: bucket.key_as_string || bucket.key,
          count: bucket.doc_count,
        })),
      },
    ];
  }
}

export default function buildStateFacets(
  aggregations: Record<string, IAggregation>,
  facetNames: string[],
): Record<string, FacetT[]> | undefined {
  const facets = facetNames.reduce((result, filterName, index, array) => {
    const facet = getValueFacet(aggregations, filterName);
    if (facet) result[filterName] = facet;
    return result;
  }, {});

  if (Object.keys(facets).length > 0) {
    return facets;
  }
}
