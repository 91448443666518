import React, { Fragment, useContext } from "react";
import { injectIntl, IntlShape } from "react-intl";
import _ from "lodash";
import { Filter, func, WithSearch } from "@elastic/react-search-ui";

import { ReactComponent as RemoveIcon } from "../../assets/icons/enclosed_x.svg";

import { hasProductBeenSelected } from "../../utils/functions";
import { ClearFilters } from "../common/ClearFilters";
import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";
import { i18nConfig } from "../../i18nConfig";
import { FilterOptionEnum } from "../../models/dataModel";

const FilterComponent = injectIntl(
  ({
    intl,
    filters,
    filterName,
    removeFilter,
    clearFilters,
  }: {
    intl: IntlShape;
    filters: Filter[];
    filterName: string;
    removeFilter: func;
    clearFilters: func;
  }) => {
    const uiStore: UIStateStore = useContext(UIStateStoreContext);
    const filterCanBeRemoved: boolean = filterName !== intl.formatMessage({ id: "facets.tua.product.allProducts" });
    const capitalizedName: string = filterName.substring(0, 1).toUpperCase() + filterName.substring(1);
    const filterLabel =
      capitalizedName === "Not version-specific"
        ? (!!i18nConfig.messages[intl.locale] &&
          i18nConfig.messages[intl.locale]["facets.tua.software_version.notVersionSpecific"]) ||
        i18nConfig.messages[intl.defaultLocale]["facets.tua.software_version.notVersionSpecific"]
        : capitalizedName;

    function remove() {
      const filterToRemove: Filter | undefined = _.find(filters, (filter: Filter) => {
        return filter.values.includes(filterName);
      });
      const isCategoryFilter = filterToRemove?.field === FilterOptionEnum.CATEGORY;

      if (isCategoryFilter) {
        clearFilters();
        uiStore.setOpenCategory(undefined);
      } else {
        removeFilter(filterToRemove?.field, filterName);
      }
    }

    return (
      <div className="filter" data-testid="selectedFilter">
        <div className="small-text">{filterLabel}</div>
        {filterCanBeRemoved && (
          <RemoveIcon title={intl.formatMessage({ id: "icon.removeFilter.title" })} onClick={remove} />
        )}
      </div>
    );
  },
);

/**
 * Renders the header section with selected filters & 'Clear filters' button.
 */
export const SelectedFilters = injectIntl(({ intl }: { intl: IntlShape }): JSX.Element => {
  const store: UIStateStore = useContext(UIStateStoreContext);

  function getFilters(filters: Filter[]): string[] {
    let allFilters: string[] = [];

    store.getOpenCategory() === "tua" &&
      !hasProductBeenSelected(filters) &&
      allFilters.push(intl.formatMessage({ id: "facets.tua.product.allProducts" }));

    filters.forEach((filter: Filter) => {
      if (_.isString(filter.values)) {
        allFilters.push(filter.values as string);
      } else {
        if (filter.field === FilterOptionEnum.CATEGORY) {
          allFilters.unshift(...(filter.values as string[]));
        } else {
          allFilters = allFilters.concat(filter.values as string[]);
        }
      }
    });

    return allFilters;
  }

  return (
    <WithSearch
      mapContextToProps={({ filters, removeFilter, clearFilters }) => ({ filters, removeFilter, clearFilters })}
    >
      {({ filters, removeFilter, clearFilters }) => (
        <Fragment>
          <div className="filter-list" data-testid="selectedFilters">
            {_.map(getFilters(filters), (filterStr: string, i: number) => {
              return (
                <FilterComponent
                  filters={filters}
                  filterName={filterStr}
                  removeFilter={removeFilter}
                  clearFilters={clearFilters}
                  key={i}
                />
              );
            })}
            {_.size(getFilters(filters)) > 0 && <ClearFilters />}
          </div>
        </Fragment>
      )}
    </WithSearch>
  );
});
