import React, { Fragment, useState } from "react";
import classNames from "classnames";
import _ from "lodash";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { Filter, WithSearch } from "@elastic/react-search-ui";

import { ReactComponent as FilterIcon } from "../../assets/icons/filter_2.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/open_x.svg";

import { SidebarContent } from "./SidebarContent";

/**
 * Renders the circle with active filter count (mobile view).
 */
const FilterCount = (): JSX.Element => {
  function getCount(filters: Filter[]): number {
    let allFilters: string[] = [];

    filters.forEach((filter) => {
      _.isString(filter.values)
        ? allFilters.push(filter.values as string)
        : (allFilters = allFilters.concat(filter.values as string[]));
    });

    return allFilters.length;
  }

  return (
    <WithSearch mapContextToProps={({ filters }) => ({ filters })}>
      {({ filters }) => (
        <div className="filter-count" data-testid="filterCount">
          {getCount(filters)}
        </div>
      )}
    </WithSearch>
  );
};

/**
 * Renders the toggle filters button in the mobile view.
 */
const ToggleButton = ({ sidebarToggled, toggleSidebar }: { sidebarToggled: boolean; toggleSidebar }): JSX.Element => {
  const intl: IntlShape = useIntl();

  const renderMobileViewHeader = (): JSX.Element => {
    return (
      <div className="mobile-sidebar-header" data-testid="mobileViewHeader">
        <h3>
          <FormattedMessage id="filterResults" />
        </h3>
        <button
          type="button"
          className="sui-layout-sidebar-toggle close-sidebar"
          onClick={() => toggleSidebar()}
          data-testid="closeFilters"
        >
          <CloseIcon title={intl.formatMessage({ id: "icon.closeFilters.title" })} />
        </button>
      </div>
    );
  };

  const renderButtonWithFilterCount = (): JSX.Element => {
    return (
      <button
        type="button"
        className="sui-layout-sidebar-toggle"
        onClick={() => toggleSidebar()}
        data-testid="openFilters"
      >
        <FilterIcon title={intl.formatMessage({ id: "filterSearchResults" })} />
        <FormattedMessage id="filterSearchResults" />
        <FilterCount />
      </button>
    );
  };

  return <Fragment>{sidebarToggled ? renderMobileViewHeader() : renderButtonWithFilterCount()}</Fragment>;
};

/**
 * Renders the custom sidebar. Custom sidebar was needed in order to include custom icons for the mobile view.
 */
export const CustomSidebar = (): JSX.Element => {
  const [sidebarToggled, setSidebarToggled] = useState<boolean>(false);

  function toggleSidebar() {
    setSidebarToggled(!sidebarToggled);
  }

  return (
    <Fragment>
      <div className="button-wrapper">
        <ToggleButton sidebarToggled={sidebarToggled} toggleSidebar={toggleSidebar} />
      </div>
      <div
        className={classNames("sui-layout-sidebar", {
          "sui-layout-sidebar--toggled": sidebarToggled,
        })}
        data-testid="sidebar"
      >
        <SidebarContent toggleSidebar={toggleSidebar} />
      </div>
    </Fragment>
  );
};
