import React from "react";
import { injectIntl, IntlShape } from "react-intl";

import { ReactComponent as SpinnerIcon } from "../../assets/icons/spinner.svg";

/**
 * Renders the spinner.
 */
export const Spinner = injectIntl(({ intl }: { intl: IntlShape }) => (
  <span className="searching-spinner centered">
    <SpinnerIcon title={intl.formatMessage({ id: "icon.spinner.title" })} />
  </span>
));
