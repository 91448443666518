 
import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { SidebarSearchField } from "../sidebar/SidebarContent";

export const ErrorView = ({ children, className, error, ...rest }): JSX.Element => {
  if (error) {
    console.log(error);

    return (
      <div className="sui-layout">
        <div className="sui-layout-body">
          <div className="sui-layout-body__inner">
            <div className="sui-layout-sidebar" data-testid="sidebar">
              <SidebarSearchField />
            </div>
            <div className={classNames("sui-search-error", className)} {...rest} data-testid="errorView">
              <FormattedMessage id="errorMessage" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return children;
};
