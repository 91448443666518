import React, { Fragment, useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import _ from "lodash";

import { ErrorBoundary, WithSearch, Result, FacetT, Filter, func, SearchBox } from "@elastic/react-search-ui";
import { ReactComponent as ClearIcon } from "../assets/icons/enclosed_x.svg";

import { checkIfVersionExists, hasVersionBeenSelected } from "../utils/functions";
import { FilterOptionEnum, FilterTypeEnum } from "../models/dataModel";

import { Results } from "./results/Results";
import { Spinner } from "./common/Spinner";
import { ErrorView } from "./common/ErrorView";
import { i18nConfig } from "../i18nConfig";
import { SearchFieldInput } from "./SearchField";

const ResetSearch = injectIntl(({ intl }: { intl: IntlShape }): JSX.Element => {
  return (
    <WithSearch
      mapContextToProps={({ results, searchTerm, reset }) => ({
        results,
        searchTerm,
        reset,
      })}
    >
      {(props) => (
        <Fragment>
          {props.searchTerm && (
            <button className="reset-search" data-testid="resetSearch" onClick={props.reset}>
              <ClearIcon
                title={intl.formatMessage({
                  id: "actions.clearSearch",
                  defaultMessage: i18nConfig.messages["en"]["actions.clearSearch"],
                })}
              />
            </button>
          )}
        </Fragment>
      )}
    </WithSearch>
  );
});

const CustomLayout = injectIntl(
  ({
    intl,
    results,
    wasSearched,
    isLoading,
    facets,
    filters,
    addFilter,
  }: {
    intl: IntlShape;
    results: Result[];
    wasSearched: boolean;
    isLoading: boolean;
    facets: Record<string, FacetT[]>;
    filters: Filter[];
    addFilter: func;
  }): JSX.Element => {
    const notVersionSpecific =
      i18nConfig.messages[intl.defaultLocale]["facets.tua.software_version.notVersionSpecific"];

    useEffect(() => {
      if (!!checkIfVersionExists(facets, notVersionSpecific) && !!hasVersionBeenSelected(filters)) {
        addFilter(FilterOptionEnum.VERSION, notVersionSpecific, FilterTypeEnum.ANY);
      }

    }, [wasSearched, notVersionSpecific]);

    return (
      <div className="sui-layout">
        {isLoading && _.isEmpty(results) ? (
          <Spinner />
        ) : (
          <div className="sui-layout-body">
            <div className="sui-layout-body__inner">
              <div className="search-field">
                <SearchBox
                  autocompleteMinimumCharacters={3}
                  autocompleteSuggestions={false}
                  shouldClearFilters={false}
                  inputView={SearchFieldInput}
                />
                <ResetSearch />
              </div>
              {wasSearched && <Results results={results} />}
            </div>
          </div>
        )}
      </div>
    );
  },
);

/**
 * Main component for the search client in online panel.
 */
export const OnlinePanelView = (): JSX.Element => {
  return (
    <div className="trimble-search in-online-panel" id="onlinePanelView" data-testid="search-for-online-panel">
      <ErrorBoundary view={ErrorView}>
        <WithSearch
          mapContextToProps={({ results, wasSearched, isLoading, facets, filters, addFilter }) => ({
            results,
            wasSearched,
            isLoading,
            facets,
            filters,
            addFilter,
          })}
        >
          {(props) => <CustomLayout {...props} />}
        </WithSearch>
      </ErrorBoundary>
    </div>
  );
};
