let deviceIdentity: string;

export default function getDeviceIdentity(): string {
  if (!deviceIdentity) {
    const navigatorInfo = window?.navigator;
    const screenInfo = window?.screen;
    deviceIdentity = navigatorInfo?.mimeTypes?.length?.toString();
    deviceIdentity += navigatorInfo?.userAgent.replace(/\D+/g, "");
    deviceIdentity += navigatorInfo?.plugins?.length;
    deviceIdentity += screenInfo?.height || "";
    deviceIdentity += screenInfo?.width || "";
    deviceIdentity += screenInfo?.pixelDepth || "";
  }

  return deviceIdentity;
}
