import React from "react";
import { func } from "@elastic/react-search-ui";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { ReactComponent as PlusIcon } from "../../assets/icons/add-expand-circle.svg";
import { i18nConfig } from "../../i18nConfig";

/**
 * Renders the 'Show more' button.
 */
export const ShowMoreOptions = injectIntl(
  ({ intl, onShowMore }: { intl: IntlShape; onShowMore: func }): JSX.Element => {
    const showMoreIconTitle: string = intl.formatMessage({
      id: "actions.showMoreOptions",
      defaultMessage: i18nConfig.messages["en"]["actions.showMoreOptions"],
    });

    return (
      <button
        type="button"
        className="show-more-options"
        onClick={() => onShowMore()}
        aria-label={showMoreIconTitle}
        data-testid="showMoreOptionsButton"
      >
        <PlusIcon title={showMoreIconTitle} />
        <FormattedMessage
          id="actions.showMoreOptions"
          defaultMessage={i18nConfig.messages["en"]["actions.showMoreOptions"]}
        />
      </button>
    );
  },
);
